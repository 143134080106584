.swatch {
  position: relative;
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: fade;
  padding: 10px;
  transition: all 400ms;
}

.palette > ul > li:not(:first-of-type) .swatch {
  border-left: none;
}

.swatch .swatch-id {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  color: #666666;
  width: 36px;
  height: 36px;
  top: 8px;
  left: 8px;
  border: 1px solid #333333;
}

.swatch .swatch-id.original {
  left: auto;
  right: 8px;
  box-shadow: inset 0 0 0 4px #f2f2f2;
}

@media (max-width: 1034px) {
  .swatch {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 760px) {
  .swatch {
    width: 100px;
    height: 100px;
  }

  .swatch .swatch-id {
    width: 22px;
    height: 22px;
  }
}
