.color-combos {
  margin: 0;
  padding: 0;
  list-style: none;
}

.color-combos .fa {
  font-size: 36px;
  color: #4d4d4d;
  margin: 0 25px;
}

.color-combos .fa.fa-pause {
  transform: rotate(90deg);
  font-size: 30px;
}

.color-combos .fa.fa-check-circle {
  color: #009245;
}

.color-combos .fa.fa-close {
  color: #d93251;
}

.color-combos .combo-row {
  padding: 0px 0 25px 0;
  border-bottom: 1px solid #ccc;
  margin: 25px 0 0;
  justify-content: flex-start;
}

.row.res-row {
  margin: 0;
}

.color-combos .suggestion {
  height: 200px;
  width: 200px;
  border-left: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
}

.color-combos .suggestion h3 {
  font-weight: 400;
  margin: 0 0 8px 0;
}

.color-combos .suggestion button {
  font-size: 14px;
}

.color-combos .suggestion .swatch {
  width: 50px;
  height: 50px;
}

.suggestion .spec {
  margin-top: 5px;
}

.color-combos__sample {
  transition: all 700ms;
}

@media (max-width: 1034px) {
  .color-combos .fa {
    margin: 0 10px;
    font-size: 25px;
  }

  .color-combos .fa.fa-pause {
    font-size: 22px;
  }
}

@media (max-width: 660px) {
  .row.res-row {
    margin: 10px auto;
  }
  .color-combos .suggestion {
    flex-basis: 100%;
    flex-direction: row;
    height: auto;
    padding: 20px 0;
  }

  .color-combos .suggestion h3 {
    margin-left: 10px;
  }

  .color-combos .suggestion [role='group'] {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 0 20px;
    align-items: center;
  }

  .color-combos .suggestion [role='group'] .swatch {
    margin-right: 10px;
  }

  .color-combos .suggestion [role='group'] .dqpl-link {
    margin-left: 10px;
  }
}
